import React from 'react';
import './connect.scss';
import Loader from "../../components/loader/Loader";
import logo from '../../assets/panel_heading.png';

const Connect = ({ status, isConnected, connect }) => {

  return (
    <>
      {status === "reconnecting" ? <Loader loading={true}/> : null}
      <div className='login_main_div'>
        <div className='middle_box_div'>
          <h1 className='box_heading'><img src={logo} alt='logo'/></h1>
          <div className='box_btn_div'>
            <button className='box_btn hover_effect'
                    onClick={isConnected === false ? connect : null}>
              {isConnected === false ? <>Connect Wallet</> : null}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Connect;