import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {mainnet} from "wagmi/chains";
import {EthereumClient, w3mConnectors, w3mProvider} from "@web3modal/ethereum";
import {Web3Modal} from "@web3modal/react";
import {WagmiConfig, configureChains, createClient} from "wagmi";
import configs from './web3/config.json';
import * as PropTypes from "prop-types";

const projectId = configs.PROJECT_ID;
const chains = [mainnet];
const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ version: 1, projectId, chains }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

const root = ReactDOM.createRoot(document.getElementById('root'));

WagmiConfig.propTypes = {
  client: PropTypes.any,
  children: PropTypes.node
};
root.render(
  <BrowserRouter>
    <WagmiConfig client={wagmiClient}>
      <App/>
    </WagmiConfig>
    <Web3Modal ethereumClient={ethereumClient} projectId={projectId} themeMode='light'/>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
