import {ethers} from "ethers";
import config from "./config.json";
import abi from "./logicAbi.json";
import nftAbi from "./nftAbi.json";

let contract;
let slothContract;

export const providerHandler = async (signer) => {
  contract = new ethers.Contract(config.contractAddress, abi, signer);
  slothContract = new ethers.Contract(config.nftAddress, nftAbi, signer);
  return true;
};

export const wl = async (wlType) => {
  const n = await contract[wlType]();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.multiplier.toNumber(),
    remainingTokens: n.maxAvailable.toNumber(),
    mintPrice: ethers.utils.formatEther(n.price.toString())
  };
}

export const pb = async () => {
  const n = await contract.PB();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.multiplier.toNumber(),
    remainingTokens: n.maxAvailable.toNumber(),
    mintPrice: ethers.utils.formatEther(n.price.toString())
  };
}

export const maxSupply = async () => {
  const n = await contract.maxSupply();
  return n.toNumber()
}

export const wlBalance = async (account, wlType) => {
  const funcName = `read${wlType}Register`;
  const n = await contract[funcName](account);
  return n.toNumber()
}

export const pbBalance = async (account) => {
  const n = await contract.readPBRegister(account);
  return n.toNumber()
}

export const totalSupply = async () => {
  const n = await slothContract.totalSupply();
  return n.toNumber()
}

export const ownerReserved = async () => {
  const n = await contract.ownerReserve();
  return n.toNumber()
}

export const getGuaranteedMints = async () => {
  const n = await contract.guaranteedMints();
  return n.toNumber()
}

export const ownerMinted = async () => {
  const n = await contract.ownerMinted();
  return n.toNumber()
}

export const checkMintCondition = async () => {
  const n = await contract.paused();
  return n;
}

export const wlMint = async (account, price, signatureTuple, amount, wlType) => {
  const funcName = `${wlType}Mint`;
  const n = await contract[funcName](account, signatureTuple, amount, { value: ethers.utils.parseEther(price.toString()) });
  await n.wait();
  return n
}

export const pbMint = async (price, account, amount) => {
  const n = await contract.publicMint(account, amount, { value: ethers.utils.parseEther(price.toString()) });
  await n.wait();
  return n
}