import React from 'react';
import './mintBox.scss';
import {useNavigate, useLocation} from "react-router-dom";
import logo from "../../assets/panel_heading.png";

const MintBox = ({
                   totalNFTs,
                   mintDetails,
                   mintCount,
                   setMintCount,
                   saleCondition,
                   totalPrice,
                   saleSupply,
                   totalMintedNFTs,
                   isUserWhitelisted,
                   loading,
                   handleWlMint,
                   isMintPaused,
                   pastMinted
                 }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const percentage = totalMintedNFTs / totalNFTs * 100

  const decreaseMint = () => {
    if (mintCount > 1) {
      setMintCount(mintCount - 1)
    }
  }

  const increaseMint = () => {
    if (mintCount < Math.min(mintDetails?.buyLimitPerWallet - pastMinted, saleSupply)) {
      setMintCount(mintCount + 1)
    }
  }

  return (
    <>
      <div className='center_div'>
        <div className='middle_div'>
          <div className='progress'>
            <div className='progress-bar' style={{ width: percentage + "%" }}></div>
            <p
              className='absolute_text'>{saleSupply !== undefined && saleSupply === 0 ? 'sold out!' : `${totalMintedNFTs} / ${totalNFTs}`}</p>
          </div>
          <div className='nav_div'>
            <button onClick={() => navigate('/og')}
                    className={`navLink ${location.pathname === '/og' ? 'active' : ''}`}>
              <li>OG</li>
            </button>
            <button onClick={() => navigate('/genesis')}
                    className={`navLink ${location.pathname === '/genesis' ? 'active' : ''}`}>
              <li>Genesis</li>
            </button>
            <button onClick={() => navigate('/whitelist')}
                    className={`navLink ${location.pathname === '/whitelist' ? 'active' : ''}`}>
              <li>Whitelist</li>
            </button>
            <button onClick={() => navigate('/public')}
                    className={`navLink ${location.pathname === '/public' ? 'active' : ''}`}>
              <li>Public</li>
            </button>
          </div>
          <div className='main_box_div'>
            <div className='left_div'>
              <h1 className='box_heading'><img src={logo} alt='logo'/></h1>
              <p className='text_desc'>Welcome to the SLOTH Fam! We are so excited for you to mint a SLOTH today. The
                IAMSLOTH Collection is a PFP collection of 5,555 unique SLOTH’S. These sloths and traits have all been
                hand-drawn over the last 12 months by artist SLOTH. This project represents a way of life, a more
                engaging, present and chill lifestyle. We are collaborators, creatives and individuals who are focused
                on the present moment. We put people first and believe in a patient more focused paced way of life. The
                sloth fam is a place for you to come rest, chill, engage with good people and remember that life is
                sometimes more than just light speed and to-do lists. Come chill… All are welcome…</p>

            </div>
            <div className='right_box_div'>
              {
                loading ? null :
                  isMintPaused ?
                    <div className='error_message'><p>Mint is temporarily paused.</p></div> :
                    isUserWhitelisted ?
                      saleCondition === 'notStarted' ?
                        <div className='error_message'>
                          <p>{`${location.pathname === '/public' ? "" : 'You’re on the list! '}Sale will begin soon.`}</p>
                        </div> :
                        saleCondition === 'saleOver' ?
                          <div className='error_message'><p>Sorry, sale is over.</p></div> :
                          saleSupply !== undefined && saleSupply === 0 ?
                            <div className='error_message'><p>Sold out!</p></div> :
                            pastMinted >= mintDetails?.buyLimitPerWallet ?
                              <div className='error_message'><p>Thanks for minting!</p></div> :
                              <>
                                <div className='mint_box_middle_div'>
                                  <div className='mind_details_div'>
                                    <p className='first_column'>Price per Mint</p>
                                    <p
                                      className='second_column'>{mintDetails !== undefined ? mintDetails.mintPrice : 0} ETH</p>
                                  </div>
                                  <div className='mind_details_div'>
                                    <p className='first_column'>Mints Remaining</p>
                                    <p
                                      className='second_column'>{mintDetails !== undefined && saleSupply !== undefined ? Math.min(mintDetails?.buyLimitPerWallet - pastMinted, saleSupply) : 0} </p>
                                  </div>
                                  <div className='counter_div'>
                                    <div className='plus_minus_btn' onClick={decreaseMint}><span></span></div>
                                    <div className='center_count'>{mintCount}</div>
                                    <div className='plus_minus_btn' onClick={increaseMint}>+</div>
                                  </div>
                                  <div className='mind_details_div total_div'>
                                    <p className='first_column'>Total Price</p>
                                    <p className='second_column'>{parseFloat(totalPrice.toFixed(5))} ETH </p>
                                  </div>
                                </div>
                                <div className='mint_btn_div'>
                                  <button className='mint_btn' onClick={handleWlMint}>Mint Now</button>
                                </div>
                              </> :
                      <div className='error_message'><p>Sorry, you’re not on this list.</p></div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MintBox;