import React, {useEffect, useState} from "react";
import './App.scss';
import {Route, Routes, Navigate, useLocation} from 'react-router-dom';
import {Slide, toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./components/loader/Loader";
import Header from "./components/header/Header";
import Connect from "./components/connect/Connect";
import MintBox from "./components/mintBox/MintBox";
import {
  checkMintCondition, getGuaranteedMints,
  maxSupply,
  ownerMinted,
  ownerReserved,
  pb,
  pbBalance, pbMint,
  providerHandler, totalSupply,
  wl,
  wlBalance, wlMint
} from "./web3/contractInteraction";
import {getSignature} from "./utils/backendApi";
import configs from './web3/config.json';
import {useAccount, useNetwork, useSwitchNetwork, useSigner} from "wagmi";
import {useWeb3Modal} from "@web3modal/react";


function App() {
  const { isConnected, address, status } = useAccount();
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [mintDetails, setMintDetails] = useState();
  const [pastMinted, setPastMinted] = useState(0);
  const [mintCount, setMintCount] = useState(1);
  const [totalNFTs, setTotalNFTs] = useState(0);
  const [saleSupply, setSaleSupply] = useState();
  const [totalMintedNFTs, setTotalMintedNFTs] = useState(0);
  const [isUserWhitelisted, setIsUserWhitelisted] = useState(true);
  const [isMintPaused, setIsMintPaused] = useState(false);
  const [saleCondition, setSaleCondition] = useState();
  const location = useLocation()
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork()
  const { data: signer } = useSigner()

  const toastOptions = {
    autoClose: 3000,
    position: "bottom-left",
    transition: Slide,
    closeButton: false,
    hideProgressBar: true,
    closeOnClick: false,
    icon: false,
  }

  useEffect(() => {
    if (status === 'connected') {
      handleChainChange()
    }
    // eslint-disable-next-line
  }, [chain, status]);

  useEffect(() => {
    accountSetup()
    // eslint-disable-next-line
  }, [address, signer, chain]);

  useEffect(() => {
    if (status === 'connected') {
      getAllDetails()
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (mintDetails !== undefined) {
      setTotalPrice(Number(mintCount) * Number(mintDetails?.mintPrice))
    }
  }, [mintCount, mintDetails?.mintPrice]);


  const getAllDetails = async () => {

    if (address !== undefined) {
      const currentTimeSeconds = parseInt(Date.now() / 1000);
      setLoading(true)

      const isPaused = await checkMintCondition()
      setIsMintPaused(isPaused)

      let details;
      let alreadyMinted;
      let tuple;
      if (location.pathname === '/public') {
        details = await pb()
        alreadyMinted = await pbBalance(address)
        setIsUserWhitelisted(true)
      } else {
        const wlType = location.pathname === '/og' ? 'OG' : location.pathname === '/genesis' ? 'Genesis' : location.pathname === '/whitelist' ? 'WL' : '';
        const listType = location.pathname === '/og' ? 1 : location.pathname === '/genesis' ? 2 : location.pathname === '/whitelist' ? 3 : '';
        details = await wl(wlType)
        alreadyMinted = await wlBalance(address, wlType)
        tuple = await getSignature(address, listType)
        if (tuple?.signature) {
          setIsUserWhitelisted(true)
          details = { ...details, buyLimitPerWallet: details?.buyLimitPerWallet * tuple?.availableSpots }
        } else {
          setIsUserWhitelisted(false)
        }
      }

      let guaranteedTokens;
      if (location.pathname === '/og' || location.pathname === '/genesis') {
        guaranteedTokens = await getGuaranteedMints()
      } else {
        guaranteedTokens = details.remainingTokens
      }
      setMintDetails({ ...details, remainingTokens: guaranteedTokens })
      setPastMinted(alreadyMinted)

      let salestatus;
      if (details.startTime < currentTimeSeconds && details.endTime > currentTimeSeconds) {
        salestatus = "started"
      } else if (details.startTime > currentTimeSeconds) {
        salestatus = "notStarted"
      } else {
        salestatus = "saleOver"
      }
      setSaleCondition(salestatus);

      const ownerRemains = await ownerReserved()
      const ownerPastMinted = await ownerMinted()
      const total = await maxSupply()
      const totalMinted = await totalSupply()

      const supplyAfterReserved = (total - ownerRemains) - (totalMinted - ownerPastMinted);
      const salesSupply = Math.min(guaranteedTokens, supplyAfterReserved);
      setTotalMintedNFTs(totalMinted)
      setSaleSupply(salesSupply <= 0 ? 0 : salesSupply)
      setTotalNFTs(total)
      setMintCount(1)
      setLoading(false)
    }
  }

  const errorCatch = (e) => {
    toast.error(
      e.code === "INSUFFICIENT_FUNDS"
        ? "Insufficient balance."
        : e.code === 'ACTION_REJECTED'
        ? 'User rejected transaction!'
        : e.code === "UNPREDICTABLE_GAS_LIMIT"
          ? "Something went wrong."
          : "Transaction unsuccessful.",
      toastOptions
    );
    setLoading(false)
  }

  const handleWlMint = async () => {
    setLoading(true)
    if (window.location.pathname === '/public') {
      try {
        await pbMint(parseFloat(totalPrice.toFixed(5)), address, mintCount)
        toast.success('Transaction successful.', toastOptions)
        await getAllDetails()
      } catch (e) {
        errorCatch(e)
      }
    } else {
      const wlType = location.pathname === '/og' ? 'OG' : location.pathname === '/genesis' ? 'Genesis' : location.pathname === '/whitelist' ? 'WL' : '';
      const listType = location.pathname === '/og' ? 1 : location.pathname === '/genesis' ? 2 : location.pathname === '/whitelist' ? 3 : null;
      const tuple = await getSignature(address, listType)
      try {
        await wlMint(address, parseFloat(totalPrice.toFixed(5)), tuple.signature, mintCount, wlType)
        toast.success('Transaction successful.', toastOptions)
        await getAllDetails()
      } catch (e) {
        errorCatch(e)
      }
    }
  }

  const accountSetup = async () => {
    if (signer !== undefined) {
      await providerHandler(signer)
      getAllDetails()
    }
  }

  const handleChainChange = async () => {
    const chainID = configs.CHAIN_ID;
    if (chain !== undefined && chain.id !== chainID) {
      setLoading(true)
      toast("Switch to ethereum mainnet", { ...toastOptions, autoClose: false, toastId: chainID });
      await switchNetwork?.(chain.id)
    } else {
      toast.dismiss(chainID);
    }
  }

  const connectHandler = () => {
    open()
  }

  return (
    <>
      <ToastContainer/>
      <Loader loading={loading}/>
      {
        status === 'reconnecting' ?
          <Loader loading={true}/> :
          isConnected === true ?
            <>
              <Header account={address}/>
              <Routes>
                <Route path='/*' element={<Navigate to='/og'/>}/>
                {["/public", "/whitelist", "/og", "/genesis"].map((path, index) =>
                  <Route path={path}
                         element={<MintBox mintDetails={mintDetails}
                                           mintCount={mintCount}
                                           setMintCount={setMintCount}
                                           saleCondition={saleCondition}
                                           handleWlMint={handleWlMint}
                                           totalPrice={totalPrice}
                                           totalNFTs={totalNFTs}
                                           saleSupply={saleSupply}
                                           totalMintedNFTs={totalMintedNFTs}
                                           isUserWhitelisted={isUserWhitelisted}
                                           isMintPaused={isMintPaused}
                                           loading={loading}
                                           pastMinted={pastMinted}/>}
                         key={index}
                  />
                )}
              </Routes>
            </>
            :
            <Connect isConnected={isConnected} connect={connectHandler} status={status}/>
      }
    </>
  );
}

export default App;
